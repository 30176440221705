import request from '@/utils/request'
import qs from 'qs'

/**
 * 查询出入库表单数据
 * @param {*} params 
 */
export function fetchBills(params) {
    return request({
        url: '/StockBills/Search',
        method: 'post',
        data: params
    });
}

/**
 * 通过编号查询单个表单数据
 * @param {*} billCode 
 */
export function fetchBillByCode(billCode) {
    return request({
        url: '/StockBills/BillCode/' + billCode,
        method: 'get'
    });
}



/**
 * 导出数据
 * @param {*} params 
 */
export function exportBill(billCode) {
    return request({
        url: '/StockBills/ExportXls?billCode=' + (billCode || ''),
        method: 'post'
    });
}


export function delBillInfo(id) {
    return request({
        url: '/StockBills/' + id,
        method: 'delete'
    });
}



/**
 * 搜索查询盘点表单列表
 * @param {*} params 
 */
export function fetchChecks(params) {
    return request({
        url: '/StockCheckInfo/Search',
        method: 'post',
        data: params
    });
}

/**
 * 查询所有的库存明细，盘点功能使用
 * @param {*} companyId 
 */
export function fetchAllBounds(companyId) {
    return request({
        url: '/StockBound/GetList?CompanyID=' + (companyId || ''),
        method: 'get'
    });
}

/**
 * 通过编号查询盘点明细
 * @param {*} billCode 
 */
export function fetchCheckDetails(billCode) {
    return request({
        url: '/StockCheckInfo/Details/' + billCode,
        method: 'get'
    });
}
export function fetchCheckReport(billCode) {
    return request({
        url: '/StockCheckInfo/Report/' + billCode,
        method: 'get'
    });
}


/**
 * 提交库存盘点数据
 * @param {*} params 
 */
export function submitCheckInfo(params) {
    return request({
        url: '/StockCheckInfo/Submit',
        method: 'post',
        data: params || {
            master: null,
            details: []
        }
    });
}

export function saveCheckEnd(params) {
    return request({
        url: '/StockCheckInfo/' + params.id,
        method: 'put',
        data: params
    });
}

export function fetchCheckDetail(params) {
    return request({
        url: '/StockCheckDetail/Search',
        method: 'post',
        data: params,
        loading: false
    });
}

export function saveCheckDetail(params) {
    return request({
        url: '/StockCheckDetail/' + params.id,
        method: 'put',
        data: params
    });
}

/**
 * 导出库存盘点数据
 * @param {*} params 
 */
export function exportCheckInfo(billCode) {
    return request({
        url: '/StockCheckInfo/ExportByCode?billCode=' + (billCode || ''),
        method: 'post'
    });
}


export function createCheckBill(CompanyID, BillCode) {
    return request({
        url: '/StockCheckInfo/CreateBill?CompanyID=' + (CompanyID || '') + '&BillCode=' + (BillCode || ''),
        method: 'get'
    });
}



export function checkSingle(BillCode, CompanyID, SerialCode, Total, Remark) {
    return request({
        url: '/StockCheckInfo/CheckSingle/' + (BillCode || '') + '?SerialCode=' + (SerialCode || '')
            + '&CompanyID=' + (CompanyID || '') + '&Total=' + Total + '&Remark=' + (Remark || '') + '&t=check',
        method: 'get',
        loading: false
    });
}

export function checkBatch(BillCode, CompanyID, IdArray, Total, Remark) {
    return request({
        url: '/StockCheckInfo/checkBatch/' + (BillCode || '') + "?CompanyID=" + (CompanyID || '')
            + '&Total=' + Total + '&Remark=' + (Remark || ''),
        method: 'post',
        data: IdArray,
    });
}



/**
 * 库存列表
 * @param {*} params 
 */
export function fetchBounds(params) {
    return request({
        url: '/StockBound/' + (params.method || 'Search'),
        method: 'post',
        data: params
    });
}


/**
 * 单个库存详情
 * @param {*} id 
 */
export function fetchBound(id) {
    return request({
        url: '/StockBound/' + id,
        method: 'get'
    });
}


/**
 * 单个库存详情
 * @param {*} id 
 */
export function fetchBoundBySerialCode(SerialCode, StoreCode, Channel, CompanyID) {
    return request({
        url: '/StockBound/GetGroupBySerialCode?SerialCodes=' + (SerialCode || '')
            + '&StoreCode=' + (StoreCode || '') + '&Channel=' + (Channel || '') + '&CompanyID=' + (CompanyID || ''),
        method: 'post'
    });
}

/**
 * 修改库存信息
 * @param {*} params 
 * @returns 
 */
export function submitBound(params) {
    return request({
        url: '/StockBound/' + (params.id || ''),
        method: 'put',
        data: params
    });
}

export function exportStoreBound() {
    return request({
        url: '/StockBound/StoreExport',
        method: 'post'
    }).then((res) => {
        window.location.href = process.env.VUE_APP_BASEURL + res.data;
    });
}


/**
 * 获取单据编号
 * @param {*} data 
 * @returns 
 */
export function fetchBillCode(data) {
    return request({
        url: 'StockBound/GetBillCode?BillType=' + data.billType + '&Prev=' + data.prev,
        method: 'get',
        data: ''
    });
}

/**
 * 入库单据  直接入库
 * @param {*} params 
 * @returns 
 */
export function submitBillIn(params) {
    return request({
        url: '/StockBills/BillIn',
        method: 'post',
        data: params || {
            bill: null,
            bounds: []
        }
    });
}
/**
 * 入库单据
 * @param {*} params 
 * @returns 
 */
export function submitPreIn(params) {
    return request({
        url: '/StockBills/BillPreIn',
        method: 'post',
        data: params || {
            bill: null,
            bounds: []
        }
    });
}

export function auditBoundIn(billCode, params) {
    return request({
        url: '/StockBills/AuditBoundIn/' + billCode,
        method: 'post',
        data: params
    });
}

export function refuseBoundIn(billCode, params) {
    return request({
        url: '/StockBills/RefuseBoundIn/' + billCode,
        method: 'post',
        data: params
    });
}
/**
 * 导入预出库表
 * @param {*} params 
 * @returns 
 */
export function billPreOutExport(params) {
    return request({
        url: '/StockBills/BillPreOutExport',
        method: 'post',
        data: params || {
            bill: null,
            bounds: []
        }
    });
}

/**
 * 出库操作
 * @param {*} params 
 * @returns 
 */
export function submitBillOut(params) {
    return request({
        url: '/StockBills/BillOut',
        method: 'post',
        data: params || {
            bill: null,
            bounds: []
        },
        loadTarget: '.billsdetial'
    });
}

/**
 * 渠道库存
 * @param {} params 
 * @returns 
 */
export function fetchStockStoreList(params) {
    return request({
        url: '/StockStore/' + (params.method || 'Search'),
        method: 'post',
        data: params
    });
}

/**
 * 
 * @param {*} detailCode 
 * @returns 
 */
export function fetchStoreDetail(detailCode) {
    return request({
        url: '/StockStore/DetailCode/' + detailCode,
        method: 'get'
    });
}

/**
 * 刷新款式的各个渠道库存
 * @param {*} detailCode 
 * @returns 
 */
export function resetSkuStore(detailCode) {
    return request({
        url: '/StockStore/StoreInit/?detailCodes=' + detailCode,
        method: 'get'
    });
}

/**
 * 渠道计划调拨
 * @param {*} params 
 * @returns 
 */
export function submitTransfer(params) {
    return request({
        url: '/StockBills/TransferDetail',
        method: 'post',
        data: params || {
            bill: null,
            details: []
        }
    });
}

/**
 * 实际库房、渠道调拨
 * @param {*} params 
 * @returns 
 */
export function submitRealTransfer(params) {
    return request({
        url: '/StockBills/Transfer',
        method: 'post',
        data: params || {
            bill: null,
            bounds: []
        }
    });
}


export function createTaxScript(id) {
    return request({
        url: '/TaxTicket/InTicketScript?FileID=' + id,
        method: 'get'
    });
}




export function boundModify(fileID) {
    return request({
        url: 'StockBound/BoundModify?FileID=' + fileID,
        method: 'get',
        loadTarget: 'load-dialog'
    });
}