<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>库存盘点</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <el-form :model="querys" ref="querys" label-width="90px" class="search-form" @submit.prevent="fetchs">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search" @keyup.enter="fetchs"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="所属公司">
          <el-col>
            <!-- <el-cascader v-model="companyId" :options="companys" clearable @change="fetchs()" /> -->
            <el-tree-select ref="companys" v-model="companyId" :data="companys" @change="fetchs();" clearable
              check-strictly :render-after-expand="false" style="min-width: 220px;" />
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="plus" @click="add()" style="display: none;">
        原盘点
      </el-button>
      <el-button type="primary" icon="plus" @click="addnew()">
        盘点
      </el-button>
      <el-button type="primary" icon="download" @click="exportEvent()">
        导出
      </el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">

      <template v-slot:status="scope">
        <span v-if="scope.row.status == -1" style="color: #ccc;">已取消</span>
        <span v-else-if="scope.row.status == 0" style="color:#67d400">盘点中</span>
        <span v-else-if="scope.row.status == 1" style="color:#f56c6c">已暂停</span>
        <span v-else-if="scope.row.status == 10" style="color:#1e00ff">已完成</span>
        <span v-else>未知状态</span>
      </template>

      <template v-slot:toolbar="scope">
        <el-button @click="edit(scope.row)" link size="small"> [查看] </el-button>
      </template>
    </static-table>
  </el-card>
</template>

<script>
import { fetchChecks, exportCheckInfo } from "@/api/stock";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "CheckIndex",
  components: { StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { prop: "billType", label: "单据类型", sortable: true, searchable: true, width: "90", },
          { prop: "billCode", label: "单据编号", sortable: true, searchable: true, width: "175", showOverflowTooltip: true, },
          { prop: "originNumber", label: "库存数", width: "65", },
          { prop: "totalNumber", label: "盘点数", width: "65", },
          { prop: "diffNumber", label: "差异数", width: "65", },
          { prop: "diffMoney", label: "差异金额", width: "90", },
          { type: "template", prop: "status", label: "状态", width: "70", sortable: true, showOverflowTooltip: true, },
          { prop: "createUserName", label: "创建人", searchable: true, width: "70", },
          { prop: "checkUerNames", label: "盘点人", searchable: true, width: "130", showOverflowTooltip: true, },
          { prop: "billDate", label: "单据时间", width: "150" },
          { prop: "createDate", label: "创建时间", width: "150" },
          { prop: "remark", label: "备注", searchable: true, width: "130", showOverflowTooltip: true, },
          { type: "toolbar", label: "操作" },
        ],
      },
      pager: {
        sidx: "id", //默认的排序字段
        sord: "descending",
      },
      companys: [],
      companyId: ''
    };
  },
  methods: {
    fetchs() {
      this.querys.filters = [];

      if (this.companyId && this.companyId.length > 0) {
        this.querys.filters.push({
          name: "companyId",
          value: this.companyId,
          operate: 6,
        });
      }

      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };


      fetchChecks(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    add() {
      this.$router.push("detail");
    },
    addnew() {
      this.$router.push("stockCheck");
    },
    edit(row) {
      //正在盘点中的数据
      if (row.status == 0 || row.status == 1) {
        this.$router.push({
          name: "StockCheck",
          query: { billCode: row.billCode, },
        });
      } else {
        this.$router.push({
          name: "StockCheck", //CheckDetail
          query: { billCode: row.billCode, },
        });
      }
    },
    exportEvent() {
      if (this.currRow) {
        exportCheckInfo(this.currRow.billCode).then((res) => {
          if (res.code == 200)
            window.location.href = process.env.VUE_APP_BASEURL + res.data;
        });
      }
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
  },
  mounted() {
    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.fetchs();
    });
  },
  watch: {
    companyId: function (newValue, oldValue) {
      if (newValue && newValue != oldValue)
        this.$util.setLocalstorage('companyIdStr', newValue);
      else
        this.$util.setLocalstorage('companyIdStr', '');
    }
  },
  created: function () {
    let companyIdStr = this.$util.getLocalstorage('companyIdStr');
    if (companyIdStr && companyIdStr.length > 0) {
      this.companyId = companyIdStr;
    }
  }
};
</script>